<template>
  <div class="tipo">
    <div class="centro">
      <h1>Consulta por Tipo de Legislação</h1>
      <BuscaSimplesCards />
      <Loading v-if="carregando" />
      <ErroResultados v-if="!carregando && erro" />
      <PainelBuscaSimplificada
        :dados="tipos"
        :generateUrl="generateUrl"
        titulo="Tipos de Legislação"
        v-if="!carregando && !erro"
      />
    </div>
  </div>
</template>

<script>
import MensagemVetoApi from "../../services/MensagemVetoApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import PainelBuscaSimplificada from "../../components/PainelBuscaSimplificada";
import BuscaSimplesCards from "../../components/pesquisa_mensagem_veto/BuscaSimplesCards";
import metaDados from "../../helpers/metaDados";

export default {
  name: "PorTipoLegislacaoMensagemVeto",
  metaInfo() {
    return {
      title: "Pesquisa de Mensagem de Veto por Tipo de Legislação",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa a todos os Ofícios de Mensagem de Veto do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa a todos os Ofícios de Mensagem de Veto do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa de Mensagens de Veto por Tipo de Legislação - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa a todos os Ofícios de Mensagem de Veto do Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa de Mensagens de Veto por Tipo de Legislação - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  data() {
    return {
      carregando: true,
      erro: false,
      tipos: [],
    };
  },
  components: {
    PainelBuscaSimplificada,
    Loading,
    ErroResultados,
    BuscaSimplesCards,
  },
  created() {
    this.mensagemVetoApi = new MensagemVetoApi();

    this.carregarTipos();
  },
  methods: {
    carregarTipos: async function() {
      try {
        if (this.mensagemVetoApi.cancelTokens.getTiposLegislacaoAgrupados) {
          this.mensagemVetoApi.cancelTokens.getTiposLegislacaoAgrupados.cancel();
        }

        this.carregando = true;
        this.erro = false;
        this.tipos = await this.mensagemVetoApi.getTiposLegislacaoAgrupados();
        this.carregando = false;
      } catch (err) {
        if (this.mensagemVetoApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
    generateUrl: function(ano, tipo) {
      return {
        name: "PesquisaMensagemVeto",
        query: { tipo_legislacao: tipo.id, ano },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2rem 0rem 1.5rem 0.5rem;
  font-size: 1.5rem;
}
.tipo {
  min-height: calc(100vh - 10rem);
  margin-bottom: 5rem;
}

p {
  text-indent: 1.5rem;
  margin: 1rem 1.5rem 1rem 0.5rem;
  font-size: 0.9rem;
  letter-spacing: 0.03rem;
  strong {
    text-transform: uppercase;
  }
}

.alto-contraste {
  p {
    color: white;
  }
}
</style>
