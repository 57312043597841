<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Projeto de Lei</th>
          <th>Ofício do Governador</th>
          <th>Última Atualização</th>
          <th>Autógrafo da Lei</th>
          <th>Em andamento?</th>
          <th>Assunto</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(projeto, index) in projetos">
          <tr :key="projeto.id">
            <td class="topo-card">
              <a
                target="_blank"
                :href="
                  `https://opine.al.go.leg.br/proposicoes/${projeto.numero_processo_projeto_lei}`
                "
              >{{ projeto.numero_processo_projeto_lei }}</a>
            </td>
            <td data-label="Ofício do Governador:">
              {{ projeto.numero_oficio_msg }}
              <br />
              {{ formataData(projeto.data_oficio) }}
            </td>
            <td data-label="Última atualização:">{{ formataData(projeto.data_ultimo_andamento) }}</td>
            <td data-label="Autógrafo da Lei:">
              <span>{{ projeto.numero_autografo_lei }}</span>
              <br />
              <span>{{ formataData(projeto.data_autografo_lei) }}</span>
            </td>
            <td data-label="Em andamento?">{{ projeto.em_tramitacao ? "Sim" : "Não" }}</td>
            <td data-label="Assunto:">{{ projeto.assunto }}</td>
            <td class="botao">
              <button
                @click="exibir(index)"
                v-if="exibirBotaoVerMais(projeto)"
              >{{ exibirMais[index] ? "ocultar" : "ver mais" }}</button>
            </td>
          </tr>
          <tr :key="projeto.id + '_detalhes'" v-show="exibirMais[index]" class="exibir-mais">
            <td colspan="7">
              <DetalhesProjeto :projeto="projeto"></DetalhesProjeto>
            </td>
          </tr>
        </template>
        <tr v-if="!projetos || projetos.length == 0" class="sem-resultados">
          <td colspan="7">Nenhum resultado encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { parseISO, format } from "date-fns";

import DetalhesProjeto from "./DetalhesProjeto.vue";

export default {
  name: "TabelaResultadosProjetoGovernador",
  props: {
    projetos: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      exibirMais: {}
    };
  },
  components: {
    DetalhesProjeto
  },
  methods: {
    formataData: function(data) {
      if (!data) {
        return "";
      }

      return format(parseISO(data), "dd/MM/yyyy");
    },
    exibir: function(index) {
      this.$set(this.exibirMais, index, !this.exibirMais[index]);
    },
    exibirBotaoVerMais: function(projeto) {
      return (
        (projeto.legislacao && projeto.legislacao.numero) ||
        projeto.numero_oficio_veto ||
        projeto.data_oficio_veto ||
        projeto.tipo_veto ||
        projeto.processo_sei_oficio_msg
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.resultados {
  table {
    border-collapse: collapse;
    width: 100%;
    a{
        cursor: pointer;
        color: #375a3a;
    }
    thead {
      border-bottom: solid #ffa900 0.15rem;
      color: #0a4a22;
      th {
        padding: 0rem 2rem;
      }
    }
    tbody {
      tr {
        margin: 1rem;
        td {
          padding: 0.5rem;
        }
        td:nth-child(2) {
          text-align: center;
        }
        td:nth-child(3) {
          text-align: center;
        }
        td:nth-child(4) {
          text-align: center;
        }
        td:nth-child(5) {
          text-align: center;
          max-width: 50rem;
        }
      }
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: #edf2ee;
      }
      .sem-resultados {
        background-color: transparent !important;
        text-align: center;
        height: 5rem;
        color: rgb(73, 73, 73);
        font-style: oblique;
      }
    }
    .botao {
      width: 7rem;
      padding-bottom: 1rem;
      text-align: center;
      margin-left: -.25rem;
      margin-top: 1rem;
      button {
        background-color: transparent;
        font-family: "Poppins";
        color: #0a4a22;
        border: #0a4a22 solid 0.15rem;
        border-radius: 0.2rem;
        padding: 0rem 1rem;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
  td {
    font-size: 1rem;
    img {
      height: 1.5rem;
      margin: 0rem 0.5rem 0rem 0.5rem;
    }
  }
}
.alto-contraste {
  table {
    thead {
      th {
        color: #ffa900;
      }
    }
    tbody {
      tr {
        margin: 1rem;
        td {
          color: white;
        }
      }
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: #202020;
      }
      a {
        color: rgb(0, 169, 211);
      }
      .sem-resultados {
        color: white !important;
      }
      .botao {
        button {
          color: #ffa900;
          border: #ffa900 solid 0.15rem;
        }
      }
    }
  }
}
td {
  .t-preto {
    display: none;
  }
  .t-branco {
    display: inline;
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      margin: 0rem !important;
      width: calc(100% - 2rem);
      tbody {
        background-color: transparent;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      tbody {
        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #edf2ee;
          width: 100% !important;
          td {
            padding: 0rem;
            margin: .5rem 1rem;
          }
          td:nth-child(2) {
            text-align: left !important;
          }
          td:nth-child(3) {
            text-align: left !important;
          }
          td:nth-child(4) {
            text-align: left !important;
          }
          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
          .acao {
            display: none;
          }
        }
      }
      tr:nth-child(even) td {
        display: block;
        margin-bottom: 0.5em;
        background-color: transparent !important;
      }

      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        font-size: .9rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #0a4a22;
        font-family: "Poppins-Medium";
        margin-right: 0.5rem;
      }
      .sem-resultados {
        padding-top: 5rem;
      }
      .topo-card {
        background-color: #375a3a;
        font-family: "Poppins";
        color: white;
        text-align: center;
        margin: 0rem 0rem 1rem;
        font-weight: 600;
        padding: 0.5rem 0rem;
        a {
          color: white;
        }
      }
      .botao {
        padding-bottom: 1rem;
        text-align: center;
        margin: 1rem auto 0rem;
        width: 100%;
        button {
          background-color: transparent;
          font-family: "Poppins";
          color: #0a4a22;
          border: #0a4a22 solid 0.15rem;
          border-radius: 0.2rem;
          padding: 0rem 2rem;
          font-size: .9rem;
        }
      }
      .exibir-mais {
        margin-top: -1rem;
        color: black;
        text-align: left;
      }
    }
  }
  .alto-contraste {
    .resultados {
      table {
        tr {
          background-color: #202020;
        }
        td::before {
          color: #c2c2c2;
        }
      }
    }
  }
}
</style>
