import { render, staticRenderFns } from "./BuscaSimplesCards.vue?vue&type=template&id=ae49d772&scoped=true&"
import script from "./BuscaSimplesCards.vue?vue&type=script&lang=js&"
export * from "./BuscaSimplesCards.vue?vue&type=script&lang=js&"
import style0 from "./BuscaSimplesCards.vue?vue&type=style&index=0&id=ae49d772&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae49d772",
  null
  
)

export default component.exports