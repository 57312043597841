import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class LegislacaoApi {
  cancelTokens = {
    getLegislacoes: null,
    getLegislacao: null,
    getMaisRecentes: null,
    getConstituicaoEstadual: null,
    getAnos: null,
    getCategorias: null,
    getTipos: null,
    getAutores: null
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getLegislacoes({
    page = 1,
    numero,
    conteudo,
    qtd_por_pagina,
    tipo_legislacao,
    categoria_legislacao,
    ementa,
    autor,
    ano,
    periodo_inicial_legislacao,
    periodo_final_legislacao,
    periodo_inicial_diario,
    periodo_final_diario,
    termo,
  }) {
    // Definir valores padrão para os parâmetros que podem ser nulos
    numero = numero !== undefined ? numero : null;
    conteudo = conteudo !== undefined ? conteudo : null;
    tipo_legislacao = tipo_legislacao !== undefined ? tipo_legislacao : null;
    categoria_legislacao = categoria_legislacao !== undefined ? categoria_legislacao : null;
    ementa = ementa !== undefined ? ementa : null;
    autor = autor !== undefined ? autor : null;
    ano = ano !== undefined ? ano : null;
    periodo_inicial_legislacao = periodo_inicial_legislacao !== undefined ? periodo_inicial_legislacao : null;
    periodo_final_legislacao = periodo_final_legislacao !== undefined ? periodo_final_legislacao : null;
    periodo_inicial_diario = periodo_inicial_diario !== undefined ? periodo_inicial_diario : null;
    periodo_final_diario = periodo_final_diario !== undefined ? periodo_final_diario : null;
    termo = termo !== undefined ? termo : null;

    // Construir o objeto de parâmetros
    const queryParams = {
        page,
        qtd_por_pagina,
        numero,
        conteudo,
        tipo_legislacao,
        categoria_legislacao,
        ementa,
        autor,
        ano,
        periodo_inicial_legislacao,
        periodo_final_legislacao,
        periodo_inicial_diario,
        periodo_final_diario,
        termo,
    };

    // Construir a query string
    const query = queryString.stringify(queryParams);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getLegislacoes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getLegislacoes = null;

    return data;
  }

  async getLegislacao(legislacaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getLegislacao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/${legislacaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getLegislacao = null;

    return data;
  }

  async getConstituicaoEstadual() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getConstituicaoEstadual = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/constituicao_estadual`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConstituicaoEstadual = null;
    return data;
  }

  async getMaisRecentes() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMaisRecentes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/mais_recentes`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMaisRecentes = null;

    return data;
  }

  async getAnos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAnos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/anos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAnos = null;

    return data;
  }

  async getCategorias() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategorias = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/categorias`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategorias = null;

    return data;
  }

  async getTipos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTipos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/tipos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipos = null;

    return data;
  }

  async getAutores() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAutores = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/autores`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAutores = null;

    return data;
  }
}

export default LegislacaoApi;
